import React from 'react';
import { number, shape } from 'prop-types';
import { Label } from '../../../../../prop-types';
import StyledLabel from '../../../../../components/styled-label';

const namespace = 'ui-pdp-s-available-quantity';

const AvailableQuantity = ({ picker: { selected, title } }) => (
  <>
    <span className={namespace}>
      <StyledLabel as="p" className={`${namespace}__title`} {...title} />
    </span>
    <input type="hidden" name="quantity" value={selected} />
  </>
);

AvailableQuantity.propTypes = {
  picker: shape({
    selected: number.isRequired,
    title: Label.isRequired,
  }).isRequired,
};

export default AvailableQuantity;
